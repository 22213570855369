import React from 'react';

import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

// redux toolkit
import { Provider } from 'react-redux';
import { store } from './Redux/store';

// react router dom
import { BrowserRouter } from 'react-router-dom'

// import component 
import App from './App';


// css
import './index.scss';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
reportWebVitals();
