import React, { useEffect, useState, Suspense } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';

// APIs :
import { GetProfileDataAPI } from './API/users';
import { CommissionAPI } from 'API/commission';
import { CommissionDataActions } from 'Redux/Slice/Commission';
// Helpers :
import { ToastContainer, toast } from 'react-toastify';
// Redux :
import { useDispatch, useSelector } from 'react-redux';
import { userDataActions } from 'Redux/Slice/Users/index';
import { LoadingStatesActions } from 'Redux/Slice/Loading/index';

// Cross Domain Storage :
import createGuest from "cross-domain-storage/guest";

// css
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';

// Lazy load components
const Home = React.lazy(() => import('./Pages/Home/Home'));
const About = React.lazy(() => import('./Pages/About/About'));
const Blogs = React.lazy(() => import('./Pages/Blogs/Blogs'));
const Payment = React.lazy(() => import('./Pages/Payment/Payment'));
const Footer = React.lazy(() => import('./Components/Footer/Footer'));
const Navbar = React.lazy(() => import('./Components/Navbar/Navbar'));
const Contact = React.lazy(() => import('./Pages/ContactUs/Contact'));
const Categories = React.lazy(() => import('./Pages/Categories/Categories'));
const ScrollUpButton = React.lazy(() => import('./Components/Atoms/ScrollUpButton/ScrollUpButton'));
const SideBar = React.lazy(() => import('./Components/SideBar/SideBar'));
const Bag = React.lazy(() => import('./Pages/Bag/Bag'));
const Teacher = React.lazy(() => import('./Pages/Teacher/Teacher'));
const BooksDetail = React.lazy(() => import('./Components/Libarary/BooksDetail/BooksDetail'));
const FreeBook = React.lazy(() => import('./Components/Libarary/FreeBook/FreeBook'));
const CardForm = React.lazy(() => import('./Components/Libarary/InfoForm/CardInfo'));
const PaymentPage = React.lazy(() => import('./Components/Libarary/Payment/Payment'));
const StudentPaymentPage = React.lazy(() => import('./Pages/Teacher/TeacherDetail/Payment/StudentPaymentPage'));
const TeacherDetailPage = React.lazy(() => import('./Pages/Teacher/TeacherDetail/TeachersDetailPage'));
const TeacherDetail = React.lazy(() => import('Pages/Teacher/TeacherCourseDetail/TeacherDetail'));
const CallJoiningForm = React.lazy(() => import('./Pages/Teacher/TeacherDetail/CallJoiningForm/CallJoiningForm'));
const PreLoader = React.lazy(() => import('Components/Atoms/PreLoader/PreLoader'));
const QuranRecite = React.lazy(() => import('Pages/Recitation/QuranRecite'));
const Donation = React.lazy(() => import('Pages/Donation/DonateUs'));
const DonationPayment = React.lazy(() => import('Pages/Donation/DonationPayment/DonationPayment'));
const DonateUs = React.lazy(() => import('Pages/Donation/DonateUs'));
const Scroll = React.lazy(() => import('Helper/SmoothScrollbar'));



const App = () => {
  let location = useLocation();
  let Dispatch = useDispatch()

  const [AuthToken, setAuthToken] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  const gettingProfileData = async (CreateGuest) => {
    Dispatch(LoadingStatesActions.setLoadingStates({ ProfileAPI: true }))
    let res = await GetProfileDataAPI()
    if (res.error != null) {

    } else {
      let userData = res.data?.result;
      Dispatch(userDataActions.setUserData(userData))
      localStorage.setItem("madrasaUserData", JSON.stringify(userData))
    }
    Dispatch(LoadingStatesActions.setLoadingStates({ ProfileAPI: false }))
  }
  var CreateGuest = createGuest(window?.location?.DashboardURL)
  useEffect(() => {

    try {

      CreateGuest.get("madrasaToken", function (error, value) {
        if (error) {
          console.log("------- ERROR ---------", error);
          localStorage.removeItem("madrasaToken")
          setAuthToken(false)
        }
        if (value) {
          console.log("----------- VALUE ----------", value);
          setAuthToken(value)
          return
        } else {
          setAuthToken(false)
        }
        console.log("----------- VALUE-asdasd ----------", value, error);
        setAuthToken(false)
      })
    } catch (err) {
      console.log("----------- CATCH ----------", err);
    }

  }, [])

  const LogOut = () => {
    CreateGuest.remove("madrasaToken", (error, value) => {
      if (error) {
        console.log("------- ERROR-CDS ---------", error);
      }
      if (value) {
        console.log("------- VALUE-CDS ---------", value);
      }
      console.log("------- RES-CDS ---------", value, error);
    })
    localStorage.clear()
    toast.warn("Logout Success");
    setTimeout(() => {
      window.location.href = "/"
    }, 500);
  }

  useEffect(() => {
    if (AuthToken && AuthToken != undefined && AuthToken != null && AuthToken?.length >= 10) {
      localStorage.setItem("madrasaToken", AuthToken)
      gettingProfileData()
    } else {
      localStorage.removeItem("madrasaToken")
      localStorage.removeItem("madrasaUserData")
      Dispatch(userDataActions.setUserData(null))
    }

    if (AuthToken == false) {
      Dispatch(LoadingStatesActions.setLoadingStates({ ProfileAPI: false }))
    }

  }, [AuthToken])

  useEffect(() => {
    return () => {};
  }, [])

  const GetCommission = async () => {
    let res = await CommissionAPI()
    if (res?.error != null) {
      toast.error(res?.error)
    } else {
      Dispatch(CommissionDataActions?.setCommissionData(res?.data?.result))
    }
  }

  useEffect(() => {
    GetCommission()
  }, [])

  return (
    <>
      <Scroll />
      {
        loading ? 
          <PreLoader />
          : 
          <>
            <ToastContainer
              position="top-center"
              autoClose={1000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
            {
              location.pathname !== '/login' && 
              <Suspense fallback={<div>Loading Navbar...</div>}><Navbar LogOut={LogOut} /></Suspense>
            }
            <Suspense fallback={<div>Loading Sidebar...</div>}><SideBar /></Suspense>
            <Routes>
              <Route exact path='/' element={<Suspense fallback={<div>Loading Home...</div>}><Home /></Suspense>} />
              <Route exact path='/recite' element={<Suspense fallback={<div>Loading Quran Recite...</div>}><QuranRecite /></Suspense>} />
              <Route exact path='/donation' element={<Suspense fallback={<div>Loading Donate Us...</div>}><DonateUs /></Suspense>} />
              <Route exact path='/donation/payment' element={<Suspense fallback={<div>Loading Donation Payment...</div>}><DonationPayment /></Suspense>} />
              <Route exact path='/teacher' element={<Suspense fallback={<div>Loading Teacher...</div>}><Teacher /></Suspense>} />
              <Route exact path='/teacher/detail' element={<Suspense fallback={<div>Loading Teacher Detail...</div>}><TeacherDetail /></Suspense>} />
              <Route exact path='/teacher/detailPage' element={<Suspense fallback={<div>Loading Teacher Detail Page...</div>}><TeacherDetailPage /></Suspense>} />
              <Route exact path='/teacher/detailPage/joiningForm' element={<Suspense fallback={<div>Loading Joining Form...</div>}><CallJoiningForm /></Suspense>} />
              <Route exact path='/teacher/detail/payment' element={<Suspense fallback={<div>Loading Student Payment...</div>}><StudentPaymentPage /></Suspense>} />
              <Route exact path='library' element={<Suspense fallback={<div>Loading Categories...</div>}><Categories /></Suspense>} />
              <Route exact path='/libarary/detail' element={<Suspense fallback={<div>Loading Books Detail...</div>}><BooksDetail /></Suspense>} />
              <Route exact path='/libarary/detail/free-book' element={<Suspense fallback={<div>Loading Free Book...</div>}><FreeBook /></Suspense>} />
              <Route exact path='/libarary/detail/checkOut' element={<Suspense fallback={<div>Loading Card Form...</div>}><CardForm /></Suspense>} />
              <Route exact path='/libarary/detail/payment' element={<Suspense fallback={<div>Loading Payment Page...</div>}><PaymentPage /></Suspense>} />
              <Route exact path='blogs/*' element={<Suspense fallback={<div>Loading Blogs...</div>}><Blogs /></Suspense>} />
              <Route exact path='about' element={<Suspense fallback={<div>Loading About...</div>}><About /></Suspense>} />
              <Route exact path='contact' element={<Suspense fallback={<div>Loading Contact...</div>}><Contact /></Suspense>} />
              <Route exact path='search' element={<Suspense fallback={<div>Loading Search...</div>}><Home /></Suspense>} />
              <Route exact path='shopping' element={<Suspense fallback={<div>Loading Payment...</div>}><Payment /></Suspense>} />
              <Route exact path='/library/sidebar' element={<Suspense fallback={<div>Loading Sidebar...</div>}><SideBar /></Suspense>} />
              <Route exact path='/library/bag' element={<Suspense fallback={<div>Loading Bag...</div>}><Bag /></Suspense>} />
              <Route exact path="*" element={<Navigate to={'/'} replace />} />
            </Routes>
            {
              location.pathname !== '/login' && 
              <Suspense fallback={<div>Loading Footer...</div>}><Footer /></Suspense>
            }
            {
              location.pathname !== '/login' && 
              <Suspense fallback={<div>Loading Scroll Up Button...</div>}><ScrollUpButton /></Suspense>
            }
          </>
      }
    </>
  );
}

export default App;
